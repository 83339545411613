.landing-feature {
  width: 100%;
  margin-top: 30px;

  @media (min-width: 1024px) {
    display: flex;
    flex-flow: row wrap;
  }

  > div {
    display: flex;
    flex-flow: column;
  }

  > div > div {
    padding-left: 40px;
    padding-right: 40px;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
  }

  &-col1 {
    @media (min-width: 1024px) {
      flex: 0 0 30%;
      padding-right: 30px;
    }
  }

  &-col2 {
    @media (min-width: 1024px) {
      flex: 0 0 40%;
    }
  }

  &-col3 {
    @media (min-width: 1024px) {
      flex: 0 0 30%;
      padding-left: 30px;
    }
  }

}

.key-features {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;

  > div {
    background: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(152, 163, 170, 0.20);
    border-radius: 30px;
    opacity: 1;
    padding: 30px 20px;
    margin: 15px 15px;
    text-align: center;
    width: calc(((100%) / 2) - 30px);

    @media(min-width: 1024px) {
      width: calc(((100%) / 4) - 30px);
    }

    @media(min-width: 1200px) {
      width: calc(((100%) / 5) - 30px);
    }

    img {
      width: 80px;
      margin-bottom: 15px;
    }
  }
}

