.Calendar {
    //display: flex;
    width: 100%;

    &_DaySelect {

        font-family: "Roboto";
        padding: 10px 15px;

    }

    &_Row {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #eeeeee;
        align-items: center;
        position: relative;

        > div:first-child {
            flex: 0 0 150px;
            //height: 50px;
            padding: 3px 0;
            height: 30px;
            line-height: 20px;
        }

        > div:not(:first-child) {
            border-left: 1px solid #eeeeee;
            //width: calc((100% - 150px) / 25);
            flex: 0 0 calc((100% - 150px)/25);
            height: 30px;
            text-align: center;
        }
    }

    &_Head {
        &Hour {
            font-weight: bold;
        }
    }

    &_Body {
        .Calendar_Row:hover {
            background: #f1f1f1;
        }
    }

    &_FragmentBtn {
        background: transparent;
        border: none;
        opacity: 0.4;
        transition: all 0.3s;
        cursor: pointer;
        text-align: center;
        padding: 0;
        width: 100%;
        height: 30px;
        //border-radius: 50%;

        &:hover {
            opacity: 1;
            background: #d0d0d0;
        }
    }

    &_EventActions {
        padding-top: 1px;
        margin-right: 5px;

        button {
            background: transparent;
            padding: 0;
            border: none;
            color: #fff;
            outline: none;
            margin-right: 5px;
            cursor: pointer;
        }
    }
}

.CalendarEvent {
    position: absolute;
    top: 0;
    height: 100%;
    background: $successDark;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    text-align: left;
    padding: 0 10px 0 5px;
    display: flex;
    align-items: center;
    margin-left: 150px;
    z-index: 1;

    &_Text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
      &Inner {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_Popup {
        position: absolute;
        bottom: calc(100% + 5px);
        left: 0;
        white-space: nowrap;
        background: #000;
        padding: 5px 10px;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(2px);
        transition: all 0.3s;
        pointer-events: none;

        &::before {
            top: 100%;
            left: 15px;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 0, 0, 0);
            border-top-color: #000000;
            border-width: 3px;
            margin-left: -3px;
        }

    }

    &:hover .CalendarEvent_ItemPopup {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}


.event-to-be-confirmed {
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 4px;
        transform: translate(0, -50%);
        border-radius: 50%;
        background: $errorMain url(/assets/images/icons/alert.svg) center no-repeat;
        background-size: 60% auto;
    }
    .fc-event-main {
        padding-left: 30px !important;

    }
}
